<template>
  <div>
    <Menu />
    <section class="planes">
      <h1 class="planes__h1">PLANES</h1>
      <!-- <div class="carousel">
        <div class="carousel_container">
            <div class="carousel-item">
              <Plan nombre='PLAN A' cantidad_veh="1 a 50 " cantidad_inspec="Hasta 1.500 Unds / Mes" maximo_cap="Hasta 5 Unds / Mes" />
            </div>           
            <div class="carousel-item">
              <Plan nombre='PLAN B' cantidad_veh="51 a 100 " cantidad_inspec="Hasta 3.000 Unds / Mes" maximo_cap="Hasta 7 Unds / Mes" />
            </div>
            <div class="carousel-item">
              <Plan nombre='PLAN C' cantidad_veh="101 a 250 " cantidad_inspec="Hasta 7.500 Unds / Mes" maximo_cap="Hasta 10 Unds / Mes" />
            </div>
            <div class="carousel-item">
              <Plan nombre='PLAN D' cantidad_veh="251 a 500 " cantidad_inspec="Hasta 20.000 Unds / Mes" maximo_cap="Hasta 20 Unds / Mes" />
            </div>
            <div class="carousel-item">
              <Plan nombre='PLAN E' cantidad_veh="de más de 500 " cantidad_inspec="ILIMITADO!" maximo_cap="ILIMITADO!" />
            </div>      
        </div>
      </div> -->
      <div class="example-3d">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide>
            <Plan nombre='PLAN A' cantidad_veh="1 a 50 " cantidad_inspec="Hasta 1.500 Unds / Mes" maximo_cap="Hasta 5 Unds / Mes" />
          </swiper-slide>
          <swiper-slide>
            <Plan nombre='PLAN B' cantidad_veh="51 a 100 " cantidad_inspec="Hasta 3.000 Unds / Mes" maximo_cap="Hasta 7 Unds / Mes" />
          </swiper-slide>
          <swiper-slide>              
            <Plan nombre='PLAN C' cantidad_veh="101 a 250 " cantidad_inspec="Hasta 7.500 Unds / Mes" maximo_cap="Hasta 10 Unds / Mes" />
          </swiper-slide>
          <swiper-slide>
            <Plan nombre='PLAN D' cantidad_veh="251 a 500 " cantidad_inspec="Hasta 20.000 Unds / Mes" maximo_cap="Hasta 20 Unds / Mes" />
          </swiper-slide>
          <swiper-slide>
            <Plan nombre='PLAN E' cantidad_veh="de más de 500 " cantidad_inspec="ILIMITADO!" maximo_cap="ILIMITADO!" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div> 
    </section>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Menu from "@/components/Menu";
import Plan from '@/components/Plan'
import Footer from "@/components/Footer";

export default {
  name: "Beneficios",
  components: {
    Menu,
    Plan,
    Footer,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
        swiperOption: {
          // slidesPerView: 3,
          initialSlide: 2,
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows : true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          pagination: {
            el: '.swiper-pagination'
          }
        }
      }
  },
  metaInfo() {
    return{
      title: 'Qinspecting |',
      titleTemplate: '%s Planes diseñados a la medida de su operación.',
      meta: [
        { 
          name: 'description', 
          content: 'Qinspecting | Tenemos planes diseñados a la medida de su operación y sus necesidades, con excelentes características enfocadas en la optimización de sus operaciones.' },
        {   
          name: 'keywords',
          content: 'Qinspecting, checklist, preoperacionales, alistamientos, Transportes, Inspecciones, ChecklistElectronicos, Truck, mantenimiento, operaciones, hseq , talentohumano, gerencia, protocolodebioseguridad, protocolos, bioseguridad, Appears, Qinspecting' 
        },
        { 
          name: 'author',
          content: 'Appears' 
        },
        { 
          name: 'robots',
          content: 'Index, Follow' 
        },
        // Tags twitter
        {
          name: 'twitter:card', 
          content: 'summary',
        },
        { 
          name: 'twitter:site', 
          content: '@appears',
        },
        {
          name: 'twitter:creator',
          content: '@appears'
        },
        {
          name: 'twitter:title', 
          content: 'Qinspecting | Planes diseñados a la medida de su operación.',    
        },
        {    
          name: 'twitter:description', 
          content: 'Qinspecting | Tenemos planes diseñados a la medida de su operación y sus necesidades, con excelentes características enfocadas en la optimización de sus operaciones.',
        },
        { 
          name: 'twitter:image', 
          content: 'https://qinspecting.com/img/qinspecting.svg',
        },
        // Tags facebook
        {
          property: 'og:locale',
          content: 'es_CO',
        },
        {
          property: 'og:site_name',
          content: 'Qinspecting',
        },
        {
          property: 'og:url',
          content: 'https://www.qinspecting.com',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: 'Qinspecting | Planes diseñados a la medida de su operación.',
        },
        {
          property: 'og:description',
          content: 'Qinspecting | Tenemos planes diseñados a la medida de su operación y sus necesidades, con excelentes características enfocadas en la optimización de sus operaciones.',
        },
        {
          property: 'og:image',
          content: 'https://qinspecting.com/img/qinspecting.svg',
        },
        {
          property: 'og:image:secure_url',
          content: 'https://qinspecting.com/img/qinspecting.svg',
        },
        {
          property: 'og:image:width',
          content: '600',
        },
        {
          property: 'og:image:height',
          content: '323',
        }    
      ]
    }
  }
};
</script>

<style lang="scss" scope>
.planes {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid #e4e4e4;
  background-image: url("../assets/img/back.svg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.planes__h1 {
  color: var(--black);
  text-align: center;
}

.s-heading p {
  color: rgba(87, 105, 117, 0.6);
  font-size: 1rem;
  margin: 5px;
  text-align: center;
}

.s-box-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty {
  display: flex;
  width: 300px;
  height: 400px;
  position: relative;
}

.s-box {
  display: flex;
  background: var(--white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 0.1px solid #d6d6d6;
  width: 95%;
  padding: 10px 6px;
  box-sizing: border-box;
  position: relative;
}

.s-box img {
  height: 100px;
}

.s-box h3 {
  color: #576975;
  letter-spacing: 1px;
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.s-box__btn {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid rgba(0, 207, 70, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background: var(--primary);
  margin-top: 10px;
}

.bar {
  width: 100px;
  height: 6px;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translateX(-50%);
  background-color: var(--primary);
  border-radius: 0px 0px 10px 10px;
  display: none;
  animation: bar 0.5s;
}

.s-box:hover {
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.08);
  transition: all ease 0.3s;
}

.s-box__btn:hover {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--white);
  transition: all ease 0.3s;
}

.s-box:hover .bar {
  display: block;
}

@keyframes bar {
  0% {
    width: 0px;
  }
  100% {
    width: 100px;
  }
}
@media (max-width: 1050px) {
  .s-box-container {
    flex-wrap: wrap;
  }

  .planes {
    height: auto;
  }

  .s-heading {
    margin: 15px;
  }

  .s-box {
    flex-grow: 1;
  }
}

.s-box__h2 {
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: 400;
}

.s-box__separator {
  width: 50px;
  height: 0.2px;
  background: var(--black);
}

.s-box__p-head {
  font-size: 15px;
  margin-bottom: 0;
}

.s-box__size {
  font-size: 17px;
  font-weight: bold;
  color: var(--primary);
}

.s-box__p {
  font-size: 15px;
  text-align: center;
}

.s-box__b {
  display: block;
}


.carousel{
  display: grid;
  justify-items: center;
  overflow-x: scroll;
  position: relative;
}

.carousel::-webkit-scrollbar {
  height: 1px;
  background: var(--secondary);
}

.carousel::-webkit-scrollbar-thumb {
  background: var(--white);
  border-left: 0.1px solid var(--secondary);
  border-right: 0.1px solid var(--secondary);
  border-top: 0.1px solid var(--secondary);
  border-bottom: 0.1px solid var(--secondary);
}

.carousel_container{
    margin: 70px 0;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
}

@media screen and (max-width: 360px) {
  .carousel_container{
    display: grid;
    justify-items: center;
  }
}

.carousel-item{
    width: 100%;
    margin: 0 5px;
    display: inline-block;
    cursor:pointer;
    transition: 450ms all;
    transform-origin: center;
    position: relative;
}

@media screen and (max-width: 1199px){
  .carousel-item{
    width: 300px;
  }
}

.carousel-item:hover ~ .carousel-item{
    transform: translate3d(0,0,0);
}

.carousel_container:hover .carousel-item{
    opacity: 0.5;
}

.carousel_container:hover .carousel-item:hover{
    transform: scale(1.2);
    opacity: 1;
    z-index: 1000;
}

.carousel-item__details{
    background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100% );
    font-size: 10px;
    opacity: 0;
    transition: 450ms opacity;
    padding:10px;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:flex;
    flex-flow: column;
    justify-content: flex-end;
    color:white;
}

.carousel-item__details:hover{
    opacity: 1;
}

.carousel-item__details p{
    margin:2px 0px;
}

.carousel-item__details--title{
    font-size: 12px;
}

/*  */

.example-3d {
    width: 100%;
    height: 500px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper {
    height: 100%;
    width: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      // height: 500px;
      text-align: center;
      font-weight: bold;
      font-size: 17px;
      // background-color: #00CF46;
      background-position: center;
      background-size: cover;
      // color: white;
    }

    .swiper-pagination {
      .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: white;
      }
    }
  }

  .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d  .swiper-slide-shadow-right {
    background-image: none;
}

</style>
