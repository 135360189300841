<template>
    <div class="s-box">
        <div class="bar"></div>
        <h2 class="s-box__h2">{{nombre}}</h2>
        <hr class="s-box__separator" />
        <p class="s-box__p-head">Diseñado para empresas de</p>
        <p class="s-box__size">{{cantidad_veh}} vehículos</p>

        <p class="s-box__p">
            Capacidad de inspecciones a realizar
            <b class="s-box__b">{{cantidad_inspec}}</b>
        </p>
        <p class="s-box__p">
            Número máximo de capacitaciones
            <b class="s-box__b">{{maximo_cap}}</b>
        </p>
        <a class="s-box__btn " href="https://api.whatsapp.com/send?phone=573137840166&amp;text=Hola!%20Estoy%20en%20la%20página%20web%20y%20quiero%20más%20información%20acerca%20de%20sus%20servicios.%20Mi%20nombre%20es..." target="_blank">Más info</a>
    </div>
</template>

<script>
export default {
    name: 'Plan',
    props:['nombre', 'cantidad_veh', 'cantidad_inspec', 'maximo_cap']
}
</script>